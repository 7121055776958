// <!-- API -->
import { ref, computed } from 'vue';
import { createEventHook } from '@vueuse/shared';

// <!-- CLASS -->

/**
 * Define the event hooks.
 * @template {string} ModalKey
 * @param {Iterable<ModalKey>} [_keys]
 */
const defineEvents = (_keys = null) => {
    // Event Hooks.
    /** @type {Vue.EventHook<ModalKey>} */
    const opened = createEventHook();
    /** @type {Vue.EventHook<ModalKey>} */
    const closed = createEventHook();

    return {
        opened,
        closed,
    };
};

/**
 * Defines the Stage class.
 * @template {string} ModalKey
 */
class Stage {
    /**
     * Instantiate a Loader class.
     * @param {Iterable<ModalKey>} [keys]
     */
    constructor(keys = new Set()) {
        const { opened, closed } = defineEvents(keys);

        // Event Triggers.
        this.reveal = opened.trigger;
        this.close = closed.trigger;

        // Event Listeners.
        this.onRevealed = opened.on;
        this.onClosed = closed.on;

        // Reactivity.
        /** @type {Vue.Ref<Array<ModalKey>>} */
        const visible = ref([]);

        /** @type {Vue.Ref<Set<ModalKey>>} */
        const ids = ref(new Set(keys));

        /** @type {Vue.ComputedRef<Set<ModalKey>>} */
        this.enabled = computed(() => {
            const active = visible.value;
            return new Set(active);
        });

        /** @type {Vue.ComputedRef<Set<ModalKey>>} */
        this.disabled = computed(() => {
            const active = visible.value;
            const inactive = [...ids.value.values()].filter(
                (key) => !active.includes(key)
            );
            return new Set(inactive);
        });

        // Methods

        /**
         * Check if a modal is open.
         * @param {ModalKey} key
         */
        this.isOpen = (key) => this.enabled.value.has(key);

        /**
         * Check if a modal is closed.
         * @param {ModalKey} key
         */
        this.isClosed = (key) => this.disabled.value.has(key);

        /**
         * Set the specified modal as open.
         * @param {ModalKey} key
         */
        const enable = (key) => {
            visible.value = [key];
        };

        /**
         * Set the specified modal as closed.
         * @param {*} key
         */
        const disable = (key) => {
            const next = visible.value.filter((modal) => modal !== key);
            visible.value = next;
        };

        // Lifecycle

        this.onRevealed((modal) => {
            enable(modal);
        });

        this.onClosed((modal) => {
            disable(modal);
        });
    }
}

// <!-- COMPOSABLE -->
/**
 * Define the loader.
 * @template {string} ModalKey
 * @param {Iterable<ModalKey>} [keys]
 */
export const useModals = (keys = new Set()) => {
    return new Stage(keys);
};

// <!-- EXPORTS -->
export default useModals;
