// <!-- API -->
import { computed, ref } from 'vue';

// <!-- UTILITIES -->
import debounce from 'just-debounce-it';

// <!-- TYPES -->
/**
 * Define component interface.
 * @template {{ show?: V.Ref<Boolean>, debounce?: { enabled?: Boolean, wait?: Number } }} [T=any]
 * @param {Partial<T>} [props]
 */
export const useModalToggle = (props) => {
    // <!-- DESTRUCTURE -->
    const { show = ref(false) } = props ?? {};
    const { enabled: isDebounced = true, wait = 50 } = props?.debounce ?? {};

    // <!-- DEFINE -->
    /** @type {V.Ref<Boolean>} Use existing reactive boolean or create a new one. */
    const showModal = show ?? ref(false);

    /** @type {V.ComputedRef<Boolean>} Test if modal is open. */
    const isOpen = computed(() => showModal.value === true);

    /** @type {V.ComputedRef<Boolean>} Test if modal is closed. */
    const isClosed = computed(() => isOpen.value !== true);

    // Create debounced setters.
    const ms = isDebounced && !Number.isNaN(wait) ? wait : 0;

    /** @private @type {() => void} Set {@link showModal} to `true`. */
    const _open = debounce(() => (showModal.value = true), ms, true);

    /** @private @type {() => void} Set {@link showModal} to `false`. */
    const _close = debounce(() => (showModal.value = false), ms, true);

    /** @type {() => Promise<void>} Create {@link Promise} that resolves once the modal has been opened. Rejects if already opened. */
    const open = async () => {
        if (isOpen.value === true) {
            throw `The modal is already open.`;
        } else {
            _open();
            return;
        }
    };

    /** @type {() => Promise<void>} Create {@link Promise} that resolves once the modal has been closed. Rejects if already open. */
    const close = async () => {
        if (isClosed.value === true) {
            throw `The modal is already closed.`;
        } else {
            _close();
            return;
        }
    };

    // <!-- EXPOSE -->
    return {
        modal: {
            open,
            close,
            isOpen,
            isClosed,
        },
    };
};

// <!-- DEFAULT -->
export default useModalToggle;
