// <!-- UTILS -->
import omit from 'just-omit';
import { inspect } from 'util';
import { resolveUnref } from '@vueuse/core';

/**
 * Debugger for tracking information about a source data object and its duplicate fields.
 *
 * @param {MaybeRef<string>} id Form identifier.
 * @param {Vue.Ref<*>} [source] Source data model.
 * @param {Vue.Ref<*>} [target] Target data model.
 */
export const useFormkitDebugger = (id, source, target) => {
    // <!-- METHODS -->
    const inspectObject = (id = '<object>', obj) => {
        console.groupCollapsed(
            `[inspect::object]: ${id} @ ${new Date().toLocaleString()}`
        );
        console.dir(obj);
        console.groupEnd();
        return inspect(obj);
    };

    const inspectObjects = (...objects) => {
        console.groupCollapsed(
            `[inspect::object]: ${id} @ ${new Date().toLocaleString()}`
        );
        const entries = objects.map((item, index) => [
            item?.label ?? `item-${index}`,
            omit(item, 'label'),
        ]);
        const result = Object.fromEntries(entries);
        console.dir(result);
        console.groupEnd();
        return inspect(result, false, 5);
    };

    /**
     * Get the debug info and optionally log it.
     * @param {import('@formkit/core').FormKitFrameworkContext} ctx FormKit context params.
     * @param {Boolean} log If true, console log the context.
     */
    const getDebugInfo = (ctx, log = false) => {
        const key = resolveUnref(id);
        const original = source?.value;
        const updated = target?.value;
        const _ctx = inspectObject(key, ctx); // Form id, FormKit context.
        if (!!log) {
            console.log(_ctx);
        }
        return {
            original,
            updated,
            isFormValid: ctx.state.valid,
        };
    };

    return {
        inspect,
        inspectObject,
        inspectObjects,
        getDebugInfo,
    };
};

// Default export.
export default { useFormkitDebugger };
