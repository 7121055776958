/**
 * Compare two string values.
 * @param {string} a
 * @param {string} b
 */
const caseInsensitiveLocaleComparator = (a, b) => {
    const _a = a.toLowerCase().trim();
    const _b = b.toLowerCase().trim();
    return _a.localeCompare(_b, 'en');
};

// <!-- EXPORT -->

export const useCaseInsensitiveLocaleCompare = () => {
    return caseInsensitiveLocaleComparator;
};
