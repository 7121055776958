import { useCaseInsensitiveLocaleCompare } from './useCaseInsensitiveLocaleCompare';

// Setup default comparators.
const localeCompare = useCaseInsensitiveLocaleCompare();

/**
 * Compare two Location display names.
 * @param {{ value: string, unassigned?: boolean }} a
 * @param {{ value: string, unassigned?: boolean }} b
 */
const locationComparator = (a, b) => {
    // Evaluate the conditions.
    const _unassignedA = a.unassigned == true;
    const _unassignedB = b.unassigned == true;

    // If A < B...
    if (_unassignedA != _unassignedB && _unassignedB == true) {
        return -1;
    }

    // If A > B...
    if (_unassignedA != _unassignedB && _unassignedA == true) {
        return 1;
    }

    // If same category, use straightforward comparison.
    return localeCompare(a.value, b.value);
};

// <!-- EXPORT -->

export const useLocationSort = () => {
    return locationComparator;
};
